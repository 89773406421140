import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Homepage = () => {
    // Responsive settings for carousel
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 468, min: 0 },
            items: 1
        }
    };

    return (
        <div className="bg-white flex flex-col items-center justify-center">
            {/* Segitiga Terpotong */}
            <div className="ml-[5w] h-96 relative lg:ml-[25vw] md:ml-[29vw] sm:-ml-[30vw]">
                <div className="absolute top-0 left-0 w-0 h-0 border-l-[75vw] lg:border-l-[50vw] md:border-l-[50vw] sm:border-l-[75vw] border-l-transparent border-b-[75vw] lg:border-b-[50vw] md:border-b-[50vw] sm:border-b-[75vw] border-b-[#7C5DAA] z-20"></div>
                <div className="absolute top-0 left-0 w-0 h-0 border-l-[75vw] lg:border-l-[50vw] md:border-l-[50vw] sm:border-l-[75vw] border-l-transparent border-t-[75vw] lg:border-t-[50vw] md:border-t-[50vw] sm:border-t-[75vw] border-t-[#FF647C] z-10"></div>
            {/* Carousel */}
            <div className="w-44 mt-20 md:w-56 md:mt-28 lg:w-64 lg:mt-36 xl:w-96 xl:mt-52 2xl:w-[28vw] 2xl:mt-72">
                    <Carousel responsive={responsive} infinite autoPlay>
                        <div>
                            <img src="https://via.placeholder.com/600x400" alt="Slide 1" className="w-full h-auto" />
                        </div>
                        <div>
                            <img src="https://via.placeholder.com/600x400" alt="Slide 2" className="w-full h-auto" />
                        </div>
                        <div>
                            <img src="https://via.placeholder.com/600x400" alt="Slide 3" className="w-full h-auto" />
                        </div>
                    </Carousel>
                </div>
            </div>

            <div className="container mx-auto p-4 flex flex-col md:flex-row items-center justify-center">
                {/* Teks di Sebelah Kiri */}
                <div className="w-full md:w-1/2 p-4 text-center md:text-left">
                    <h1 className="text-5xl md:text-6xl font-extrabold mb-4 bg-gradient-to-r from-[#7C5DAA] to-[#FF647C] text-transparent bg-clip-text pb-2 drop-shadow-lg">Halo, Sobat Rangers!</h1>
                    <p className="text-lg md:text-lg font-bold"> Siap jadi pemimpin untuk para</p>
                    <p className="text-lg md:text-lg font-bold">Generasi Muda dimasa depan?</p>
                </div>

            </div>
        </div>
    );
};

export default Homepage;